import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Context } from '../App'
import baseURL from '../baseURL'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object({
        highestDegree: yup
        .string()
        .required('Required'),
        schoolName: yup
        .string()
        .required('Required'),
        schoolAddress: yup
        .string()
        .required('Required'),
})

const EditEducation = () => {

    const [students, setStudents] = useState([])
    const navigate = useNavigate()
    const [userName, setUserName] = useContext(Context)
    const token = localStorage.getItem('token')
    const id = localStorage.getItem('id')

    useEffect(() => {
        if (!token) {
            return navigate('/login')
        }

        axios.post(`${baseURL}/education`, { id })
            .then((response) => {
                setStudents(response.data)
            })
            .catch((error) => {
                alert(error)
            })

    }, [token, navigate])

    const formik = useFormik({

        initialValues: {
            highestDegree: '',
            schoolName: '',
            schoolAddress: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            axios.post(`${baseURL}/editeducation`, {
                highestDegree: values.highestDegree,
                schoolName: values.schoolName,
                schoolAddress: values.schoolAddress,
                id
            })
                .then((response) => {
                    alert('Education updated')
                    navigate('/profile')
                })
                .catch((error) => {
                    alert(error.response.data)
                })
        }
    })


return (
    <div className='min-h-screen mx-4 py-16'>



        <div className='max-w-[400px] mx-auto'>
            <div className=''>

                <h1 className='text-center text-xl mb-8'>
                    Edit education for user: {userName}
                </h1>

                <form onSubmit={formik.handleSubmit} className='flex flex-col mb-4'>
                    <label className='mb-1'>Highest Degree:</label>
                    <input
                            id="highestDegree"
                            name="highestDegree"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.highestDegree}
                            placeholder={students.highestDegree}
                            className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
                        {formik.touched.highestDegree && formik.errors.highestDegree ? <div className='pl-1 text-red-500'>{formik.errors.highestDegree}</div> : null}
                        
                        <label className='mb-1'>School Name:</label>
                        <input
                            id="schoolName"
                            name="schoolName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.schoolName}
                            placeholder={students.schoolName}
                            className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
                        {formik.touched.schoolName && formik.errors.schoolName ? <div className='pl-1 text-red-500'>{formik.errors.schoolName}</div> : null}
                        
                    <label className='mb-1'>School Address:</label>

                    <input
                            id="schoolAddress"
                            name="schoolAddress"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.schoolAddress}
                            placeholder={students.schoolAddress}
                            className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
                        {formik.touched.schoolAddress && formik.errors.schoolAddress ? <div className='pl-1 text-red-500'>{formik.errors.schoolAddress}</div> : null}

                    <input type='submit' value='Update' className='bg-purple-700 text-white p-2 rounded-md hover:bg-purple-800' />
                </form>


            </div>
            <Link to={'/profile'} className='text-purple-600'>Back To Profile</Link>
        </div>

    </div>

)
}

export default EditEducation