import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object({
  name: yup
  .string()
  .required('Required'),
  email: yup
  .string()
  .email('Invalid email adress')
  .required('Required'),
  phone: yup
  .string()
  .min(10, 'Atleast 10 characters')
  .max(15, 'Max 15 characters')
  .required('Required')
})

const Contact = () => {

  const formik = useFormik({

    initialValues: {
      name: '',
      email: '',
      phone: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      console.log('hello')
    }
  }
)

  return (
    <div className='min-h-screen pt-20'>
      <h2 className='text-xl text-center mb-2'>Contact Us</h2>
      <form onSubmit={formik.handleSubmit} className='flex flex-col max-w-[400px] mx-auto p-2'>
        <label className='mb-1'>Name</label>
        <input
         id="name"
         name="name"
         type="text"
         onChange={formik.handleChange} 
         onBlur={formik.handleBlur}
         value={formik.values.name}
        placeholder='Name' 
        className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
        {formik.touched.name && formik.errors.name ? <div className='pl-1 text-red-600'>{formik.errors.name}</div> : null}
        
        <label className='mb-1'>Email</label>
        <input
         id="email"
         name="email"
         type="text"
         onChange={formik.handleChange} 
         onBlur={formik.handleBlur}
         value={formik.values.email}
        placeholder='user@example.com' 
        className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
        {formik.touched.email && formik.errors.email ? <div className='pl-1 text-red-600'>{formik.errors.email}</div> : null}
        
        
        <label className='mb-1'>Phone</label>
        <input
         id="phone"
         name="phone"
         type="text"
         onChange={formik.handleChange} 
         onBlur={formik.handleBlur}
         value={formik.values.phone}
        placeholder='1234567890' 
        className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
        {formik.touched.phone && formik.errors.phone ? <div className='pl-1 text-red-600'>{formik.errors.phone}</div> : null}
        

       
        {/* <label className='mb-1'>Phone</label>
        <input placeholder='Phone' className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
        <label className='mb-1'>Business Name</label>
        <input placeholder='Business Name' className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
        <label className='mb-1'>Business Type</label>
        <input placeholder='Business Type' className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
        <label className='mb-1'>Existing Website (if applicable)</label>
        <input placeholder='Existing Website' className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
        <label className='mb-1'>Preffered Contact Method</label>
        <input placeholder='Preffered Contact Method' className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
        <label className='mb-1'>Best Time to Contact</label>
        <input placeholder='Best Time to Contact' className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' /> */}
        <button type='submit' className='p-2 mt-4 bg-purple-600 text-white font-semibold rounded-md hover:bg-purple-700'>Submit</button>
      </form>
    </div>
  )
}

export default Contact