import React, {useState} from 'react'
import { Link } from 'react-router-dom'

const How = () => {
  const [show, setShow] = useState(false)

  return (
    <div className='px-4 sm:mx-0 py-8 md:py-16 bg-purple-600 text-white'>
      <div className='max-w-[600px] text-center mx-auto'>
        <h3 className='text-2xl xl:text-4xl font-semibold tracking-tight mb-8'>How Can We Get Started?</h3>
        <button onClick={() => setShow((prev) => !prev)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
</svg>
</button>
{show && <div>

        <p className='text-lg mb-8 text-left sm:text-center'>All you have to do is register for an account and you will be able to unlock sitefix's true potential. For the complimentary site evaluation we have a short questionaire that you can fill out. Once complete and submitted, we will review and be in contact with you to present our complimentary review and plan.</p>
        <Link to={'/register'} className='p-2 bg-black text-white  mr-2 font-semibold'>Get Started</Link>
</div>}
      </div>
    </div>
  )
}

export default How