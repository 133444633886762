import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import {Context} from '../App'
import baseURL from '../baseURL'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object({
  username: yup
  .string()
  .email('Invalid email adress')
  .required('Required'),
  password: yup
  .string()
  .min(5, 'Atleast 5 characters')
  .max(35, 'Max 35 characters')
  .required('Required'),
})

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isVerified, setIsVerified] = useState(false)

  const [userName, setUserName] = useContext(Context)

  const navigate = useNavigate()

  const token = localStorage.getItem('token')

  
  useEffect(() => {
    if(token){
      return navigate('/dashboard')
    }
  }, [])
  
  const formik = useFormik({

    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {
      // values.preventDefault()

    if(!values.username || !values.password){
      return alert ('Please fill in all fields')
    }

    axios.post(`${baseURL}/login`, {
      username: values.username,
      password: values.password
    })
    .then((response) => {
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('username', response.data.username)
      localStorage.setItem('student', JSON.stringify(response.data))
      localStorage.setItem('id', response.data._id)
      localStorage.setItem('isVerified', response.data.isVerified)
      localStorage.setItem('accountType', response.data.accountType)
      setUserName(response.data.username)
      setIsVerified(response.data.isVerified)
      alert('Successfully logged in')
      if(!response.data.isVerified){
        return navigate('/verify')
      }
      navigate('/dashboard')
    })
    .catch((error) => {
      alert(error.response.data)
    })
    }
  })

  const formSubmit = (e) => {
    e.preventDefault()

    if(!username || !password){
      return alert ('Please fill in all fields')
    }

    axios.post(`${baseURL}/login`, {
      username,
      password
    })
    .then((response) => {
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('username', response.data.username)
      localStorage.setItem('student', JSON.stringify(response.data))
      localStorage.setItem('id', response.data._id)
      localStorage.setItem('isVerified', response.data.isVerified)
      localStorage.setItem('accountType', response.data.accountType)
      setUserName(response.data.username)
      setIsVerified(response.data.isVerified)
      alert('Successfully logged in')
      if(!response.data.isVerified){
        return navigate('/verify')
      }
      navigate('/dashboard')
    })
    .catch((error) => {
      alert(error.response.data)
    })
  }

  return (
    <div className='min-h-screen max-w-[400px] mx-auto p-2 mt-20'>
      <h2 className='text-center mb-2 text-xl'>Login</h2>
      

      <form onSubmit={formik.handleSubmit} className='flex flex-col mb-4'>
        
        <label className='mb-1'>Username:</label>
        <input
        id="username"
        name="username"
        type="text"
        onChange={formik.handleChange} 
        onBlur={formik.handleBlur}
        value={formik.values.username}
        className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
        placeholder='John.Smith@example.com'></input>
        {formik.touched.username && formik.errors.username ? <div className='pl-1 text-red-600'>{formik.errors.username}</div> : null}
        
        <label className='mb-1'>Password:</label>
        <input
        id="password"
        name="password"
        type="password"
        onChange={formik.handleChange} 
        onBlur={formik.handleBlur}
        value={formik.values.password}
        className='p-2 border-[1px] border-gray-400 rounded-md'
        placeholder='••••••••'></input>
        {formik.touched.password && formik.errors.password ? <div className='pl-1 text-red-600'>{formik.errors.password}</div> : null}
        <Link to={'/reset'} className='text-sm font-semibold mb-8 text-purple-600'>Forgot password?</Link>
        
        <input type='submit' value='Login' className='bg-purple-600 text-white p-2 rounded-md hover:bg-purple-700'/>
      </form>
      
      
      {/* <form className='flex flex-col mb-4'>
        <label className='mb-1'>Username:</label>
        <input
        id="username"
        name="username"
        type="text"
        onChange={(event) => setUsername(event.target.value)} 
        onBlur={formik.handleBlur}
        value={username}
        className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
        placeholder='John.Smith@example.com'></input>
        {formik.touched.username && formik.errors.username ? <div className='pl-1 text-red-500'>{formik.errors.username}</div> : null}
        <label className='mb-1'>Password:</label>
        <input
        id="password"
        name="password"
        type="password"
        onChange={(event) => setPassword(event.target.value)} 
        onBlur={formik.handleBlur}
        value={password}
        className='p-2 border-[1px] border-gray-400 rounded-md'
        placeholder='••••••••'></input>
        {formik.touched.password && formik.errors.password ? <div className='pl-1 text-red-500'>{formik.errors.password}</div> : null}
        <Link to={'/reset'} className='text-sm font-semibold mb-8 text-[#ff4f5a]'>Forgot password?</Link>
        <input onClick={formSubmit} type='submit' value='Login' className='bg-[#ff4f5a] text-white p-2 rounded-md hover:bg-[#d83e48]'/>
      </form> */}

      <p className='border-t-[1px] border-gray-400 pt-2'>Don't have an account? <Link to='/register' className='text-purple-600'>Register</Link></p>
    </div>
  )
}

export default Login