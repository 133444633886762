import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Context } from '../App'
import baseURL from '../baseURL'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object({
    firstName: yup
        .string()
        .required('Required'),
    lastName: yup
        .string()
        .required('Required'),
})

const EditProfile = () => {

    const [students, setStudents] = useState([])
    const navigate = useNavigate()
    const [userName, setUserName] = useContext(Context)
    const token = localStorage.getItem('token')
    const id = localStorage.getItem('id')

    useEffect(() => {
        if (!token) {
            return navigate('/login')
        }

        axios.post(`${baseURL}/landing`, { id })
            .then((response) => {
                setStudents(response.data)
            })
            .catch((error) => {
                alert(error)
            })

    }, [token, navigate])

    const formik = useFormik({

        initialValues: {
            firstName: '',
            lastName: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            axios.post(`${baseURL}/editprofile`, {
                firstName: values.firstName,
                lastName: values.lastName,
                id,
            })
                .then((response) => {
                    alert('Profile updated')
                    navigate('/profile')
                })
                .catch((error) => {
                    alert(error.response.data)
                })
        }
    }
    )

    return (
        <div className='min-h-screen mx-4 py-16'>

            <div className='max-w-[400px] mx-auto'>
                <div>

                    <h1 className='text-center text-xl mb-8'>
                        Edit profile for user: {userName}
                    </h1>


                    <form onSubmit={formik.handleSubmit} className='flex flex-col mb-4'>
                        <label className='mb-1'>First Name:</label>
                        <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            placeholder={students.firstName}
                            className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
                        {formik.touched.firstName && formik.errors.firstName ? <div className='pl-1 text-red-500'>{formik.errors.firstName}</div> : null}

                        <label className='mb-1'>Last Name:</label>
                        <input
                            id="lastName"
                            name="lastName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            placeholder={students.lastName}
                            className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
                        {formik.touched.lastName && formik.errors.lastName ? <div className='pl-1 text-red-500'>{formik.errors.lastName}</div> : null}

                        <input type='submit' value='Update' className='mt-4 bg-purple-700 text-white p-2 rounded-md hover:bg-purple-800' />
                    </form>
                </div>
                <Link to={'/profile'} className='text-purple-600'>Back To Profile</Link>
            </div>
        </div>

    )
}

export default EditProfile