import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import baseURL from '../baseURL'

const Admin = () => {

  const [posts, setPosts] = useState([])
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const id = localStorage.getItem('id')
  const admin = localStorage.getItem('accountType')
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    if (!token) {
      return navigate('/login')
    }

    if (admin !== 'admin') {
      return navigate('/dashboard')
    }

    axios.get(`${baseURL}/post`)
      .then((response) => {
        setPosts(response.data)
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }, [token, update])

  const approvePost = ({ postId }) => {
    axios.post(`${baseURL}/post/${postId}/approve`, {
      admin
    })
      .then((response) => {
        alert('Approved the post')
        setUpdate((prev) => !prev)
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }

  const rejectPost = ({ postId }) => {
    axios.post(`${baseURL}/post/${postId}/reject`, {
      admin
    })
      .then((response) => {
        alert('Rejected the post')
        setUpdate((prev) => !prev)
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }

  const deletePost = ({ postId, postCreator }) => {

    if (postCreator !== id) {
      return alert('Error, can only delete your own post.')
    }

    axios.delete(`${baseURL}/post/${postId}`)
      .then((response) => {
        alert('Post Deleted')
        window.location.reload()
      })
      .catch((error) => {
        alert(error.response.data)
      })

  }

  return (
    <div className='min-h-screen mx-4 md:mx-8 lg:mx-16 xl:mx-32'>

      <div className='max-w-full mb-4'>
        <h1 className='mb-4 text-xl'>
          Admin Dashboard
        </h1>
        <Link to={'/admin'} className='p-2 m-1 bg-purple-800 text-white rounded-md'>Posts</Link>
        <Link to={'/adminuser'} className='p-2 m-1 bg-purple-700 text-white rounded-md hover:bg-purple-800'>Users</Link>
        <Link to={'/admincomment'}  className='p-2 m-1 bg-purple-700 text-white rounded-md hover:bg-purple-800'>Comments</Link>
      </div>

      
        <div className='relative overflow-x-auto shadow-md'>

          <table className='w-full text-sm text-left text-gray-500'>
            <thead className='text-xs text-gray-700 uppercase bg-gray-300'>
              <tr>
                <th scope='col' className='px-6 py-3'>Title</th>
                <th scope='col' className='px-6 py-3'>Question</th>
                <th scope='col' className='px-6 py-3'>Creator</th>
                <th scope='col' className='px-6 py-3'>Status</th>
                <th scope='col' className='px-6 py-3'>Options</th>
              </tr>
            </thead>
            <tbody>
              {posts.map((post) => {
                return (
                  <tr key={post._id} className='border-b-[1px] border-gray-300 hover:bg-gray-200'>
                    <th scope='row' className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap">
                      <Link to={`/post/${post._id}`}>
                        {post.title}
                      </Link>
                    </th>
                    <td className='px-6 py-1'>
                      <Link to={`/post/${post._id}`}>
                        {post.question}
                      </Link>
                    </td>
                    <td className='px-6 py-1'>
                      <Link to={`/profile/${post.creator}`}>
                        {post.username}
                      </Link>
                    </td>
                    <td className='px-6 py-1'>{post.isApproved ? 'approved' : 'rejected'}</td>
                    <td className='px-6 py-1'>
                      {post.isApproved ?

                        <button onClick={() => { rejectPost({ postId: post._id }) }}>

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>

                        </button>
                        :
                        <button onClick={() => { approvePost({ postId: post._id }) }}>

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>

                        </button>
                      }
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>
        </div>
    </div>

  )
}

export default Admin