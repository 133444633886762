import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Context } from '../App'
import baseURL from '../baseURL'

const Work = () => {

  const [students, setStudents] = useState([])
  const navigate = useNavigate()

  const [userName, setUserName] = useContext(Context)

  const token = localStorage.getItem('token')
  const student = localStorage.getItem('student')
  const id = localStorage.getItem('id')

  useEffect(() => {
    if (!token) {
      return navigate('/login')
    }

    axios.post(`${baseURL}/work`, { id })
      .then((response) => {
        setStudents(response.data)
      })
      .catch((error) => {
        alert(error)
      })

  }, [token, navigate])


  return (
    <div className='mt-8'>

      <div className='max-w-[500px] mx-auto px-2'>

      <div className='flex justify-between'>
        <h1 className='text-center text-xl'>
          Work:
        </h1>

          <Link to={'/editwork'} className='text-gray-600 rounded-full my-auto hover:text-white hover:bg-purple-600'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>

          </Link>
        </div>

        <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
          <p className='mr-2'>Experience Level: </p>
          <p className='text-gray-700'>{students.experienceLevel}</p>
        </div>

        <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
          <p className='mr-2'>Job Title: </p>
          <p className='text-gray-700'>{students.jobTitle}</p>
        </div>

        <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
          <p className='mr-2'>Company Name: </p>
          <p className='text-gray-700'>{students.companyName}</p>
        </div>

        <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
          <p className='mr-2'>Job Profile: </p>
          <p className='text-gray-700'>{students.jobProfile}</p>
        </div>

        <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
          <p className='mr-2'>Skills: </p>
          <p className='text-gray-700'>{students.skills}</p>
        </div>
      </div>
    </div>
  )
}

export default Work