import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import baseURL from '../baseURL'
import { useFormik } from 'formik'
import * as yup from 'yup'


const validationSchema = yup.object({
  firstName: yup
  .string()
  .max(35, 'Max 35 characters')
  .required('Required'),
  lastName: yup
  .string()
  .max(35, 'Max 35 characters')
  .required('Required'),
  username: yup
  .string()
  .email('Invalid email adress')
  .required('Required'),
  password: yup
  .string()
  .min(5, 'Atleast 5 characters')
  .max(35, 'Max 35 characters')
  .required('Required'),
  retryPassword: yup
  .string()
  .min(5, 'Atleast 5 characters')
  .max(35, 'Max 35 characters')
  .required('Required'),
})

const Register = () => {

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [retryPassword, setRetryPassword] = useState('')

  const navigate = useNavigate()

  const token = localStorage.getItem('token')

  useEffect(() => {
    if(token){
      return navigate('/landing')
    }
  }, [])

  const formik = useFormik({

    initialValues: {
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      retryPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, {setSubmitting, resetForm}) => {

      if ( !values.firstName || !values.lastName || !values.password || !values.retryPassword) {
        return alert('Please fill out all fields.')
      }
  
      if (values.password !== values.retryPassword) {
        return alert('Passwords do not match.')
      }
  
      axios.post(`${baseURL}/register`, {
        firstName: values.firstName,
        lastName: values.lastName,
        username: values.username,
        password: values.password
      })
      .then((response) => {
        alert('New user created')
        navigate('/login')
      })
      .catch((error) => {
        alert(error.response.data)
      })
    }
  })

  const formSubmit = (e) => {
    e.preventDefault()
    
    if ( !firstName || !lastName || !password || !retryPassword) {
      return alert('Please fill out all fields.')
    }

    if (password !== retryPassword) {
      return alert('Passwords do not match.')
    }

    axios.post(`${baseURL}/register`, {
      firstName,
      lastName,
      username,
      password
    })
    .then((response) => {
      alert('New user created')
      navigate('/login')
    })
    .catch((error) => {
      alert(error.response.data)
    })
  }

  return (
    <div className='min-h-screen max-w-[400px] mx-auto p-2 mt-20'>
      <h2 className='text-center mb-2 text-xl'>Register</h2>
      <form onSubmit={formik.handleSubmit} className='flex flex-col mb-4'>

      <label className='mb-1'>First Name:</label>
        <input 
        id="firstName"
        name="firstName"
        type="text"
        onChange={formik.handleChange} 
        onBlur={formik.handleBlur}
        value={formik.values.firstName}
        className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
        placeholder='John'></input>
        {formik.touched.firstName && formik.errors.firstName ? <div className='pl-1 text-red-600'>{formik.errors.firstName}</div> : null}
        

        <label className='mb-1'>Last Name:</label>
        <input 
        id="lastName"
        name="lastName"
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.lastName}
        className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
        placeholder='Smith'></input>
        {formik.touched.lastName && formik.errors.lastName ? <div className='pl-1 text-red-600'>{formik.errors.lastName}</div> : null}

        <label className='mb-1'>Username (Email Address):</label>
        <input 
        id="username"
        name="username"
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.username}
        className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
        placeholder='John.Smith@example.com'></input>
        {formik.touched.username && formik.errors.username ? <div className='pl-1 text-red-600'>{formik.errors.username}</div> : null}
        

        <label className='mb-1'>Password:</label>
        <input 
        id="password"
        name="password"
        type="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
        placeholder='••••••••'></input>
        {formik.touched.password && formik.errors.password ? <div className='pl-1 text-red-600'>{formik.errors.password}</div> : null}


        <label className='mb-1'>Retry Password:</label>
        <input 
        id="retryPassword"
        name="retryPassword"
        type="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.retryPassword}
        className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
        placeholder='••••••••'></input>
        {formik.touched.retryPassword && formik.errors.retryPassword ? <div className='pl-1 text-red-600'>{formik.errors.retryPassword}</div> : null}

      <input type='submit' value='Register' className='bg-purple-600 text-white p-2 rounded-md hover:bg-purple-700'/>
      </form>

      <p className='border-t-[1px] border-gray-400 pt-2'>Already have an account? <Link to='/login' className='text-purple-600'>Login</Link></p>

    </div>
  )
}

export default Register