import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import baseURL from '../baseURL'

const AdminUser = () => {

  const [students, setStudents] = useState([])
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const id = localStorage.getItem('id')
  const admin = localStorage.getItem('accountType')
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    if (!token) {
      return navigate('/login')
    }

    if (admin !== 'admin') {
      return navigate('/dashboard')
    }

    axios.get(`${baseURL}/`)
      .then((response) => {
        setStudents(response.data)
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }, [token, update])

  const approvePost = ({ studentId }) => {
    axios.post(`${baseURL}/student/${studentId}/approve`, {
      admin
    })
      .then((response) => {
        alert('Approved the student')
        setUpdate((prev) => !prev)
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }

  const rejectPost = ({ studentId }) => {
    axios.student(`${baseURL}/student/${studentId}/reject`, {
      admin
    })
      .then((response) => {
        alert('Rejected the student')
        setUpdate((prev) => !prev)
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }

  const deleteUser = ({ studentId }) => {

    alert(`you clicked delete button for ${studentId}`)

    const result = prompt('Type delete to confirm deletion?')
    console.log(result)
    if (!result) {
      return (
        alert("user not deleted")
      )
    }

    if (result === 'delete' || result === 'Delete' || result === 'DELETE') {
      axios.delete(`${baseURL}/${studentId}`)
        .then((response) => {
          alert('User Deleted')
          setUpdate((prev) => !prev)
        })
        .catch((error) => {
          alert(error.response.data)
        })
    }

    // if (postCreator !== id) {
    //   return alert('Error, can only delete your own post.')
    // }

    // axios.delete(`${baseURL}/student/${studentId}`)
    //   .then((response) => {
    //     alert('Post Deleted')
    //     window.location.reload()
    //   })
    //   .catch((error) => {
    //     alert(error.response.data)
    //   })

  }

  return (
    <div className='min-h-screen mx-4 md:mx-8 lg:mx-16 xl:mx-32'>

      <div className='max-w-full mb-4'>
        <h1 className='mb-4 text-xl'>
          Admin Dashboard
        </h1>
        <Link to={'/admin'} className='p-2 m-1 bg-purple-700 text-white rounded-md hover:bg-purple-800'>Posts</Link>
        <Link to={'/adminuser'} className='p-2 m-1 bg-purple-800 text-white rounded-md'>Users</Link>
        <Link to={'/admincomment'} className='p-2 m-1 bg-purple-700 text-white rounded-md hover:bg-purple-800'>Comments</Link>
      </div>


      <div className='relative overflow-x-auto shadow-md'>

        <table className='w-full text-sm text-left text-gray-500'>
          <thead className='text-xs text-gray-700 uppercase bg-gray-300'>
            <tr>
              <th scope='col' className='px-6 py-3'>Username</th>
              <th scope='col' className='px-6 py-3'>First Name</th>
              <th scope='col' className='px-6 py-3'>Last Name</th>
              <th scope='col' className='px-6 py-3'>Account</th>
              <th scope='col' className='px-6 py-3'>Options</th>
            </tr>
          </thead>
          <tbody>
            {students.map((student) => {
              return (
                <tr key={student._id} className='border-b-[1px] border-gray-300 hover:bg-gray-200'>
                  <th scope='row' className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap">
                    <Link to={`/profile/${student._id}`}>
                      {student.username}
                    </Link>
                  </th>
                  <td className='px-6 py-1'>
                    {student.firstName}
                  </td>
                  <td className='px-6 py-1'>
                    {student.lastName}
                  </td>
                  <td className='px-6 py-1'>{student.isVerified ? 'verified' : 'not verified'}</td>
                  <td className='px-6 py-1 inline-flex'>
                    {student.isVerified ?
<div class="group relative">
                      <button>
                        {/* <button onClick={() => { rejectPost({ studentId: student._id }) }}> */}

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                      </button>
                                            <span class="absolute z-10 bottom-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Approve</span>
                                            </div>
                      :
                      <div class="group relative">
                      <button>
                        {/* <button onClick={() => { approvePost({ studentId: student._id }) }}> */}

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                      </button>
                                            <span class="absolute z-10 bottom-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Reject</span>
                                            </div>
                    }
                    <div class="group relative">
                      <button onClick={() => deleteUser({ studentId: student._id })} className='hover:text-black'>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>


                      </button>
                      <span class="absolute z-10 bottom-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Delete</span>
                    </div>
                  </td>
                </tr>
              )
            })}

          </tbody>
        </table>
      </div>
    </div>

  )
}

export default AdminUser