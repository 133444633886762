import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import baseURL from '../baseURL'

const ViewProfile = () => {

  const { profileID } = useParams()
  const [students, setStudents] = useState([])
  const [username, setUsername] = useState(localStorage.getItem('username'))
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const [posts, setPosts] = useState([])


  useEffect(() => {
    if (!token) {
      return navigate('/login')
    }

    axios.get(`${baseURL}/profile/${profileID}`)
      .then((response) => {
        // console.log(response)
        setStudents(response.data)
        // setQuestion(response.data._id)
      })
      .catch((error) => {
        alert(error.response.data)
        navigate('/community')
      })

    axios.get(`${baseURL}/post`)
      .then((response) => {
        setPosts(response.data)
      })
      .catch((error) => {
        alert(error.response.data)
      })

  }, [token])

  return (
    <div className='min-h-screen mx-4 md:mx-8 lg:mx-16 xl:mx-32'>


      <div>

        <div className='max-w-[500px] mx-auto px-2'>
        <h3 className='text-xl mb-8'>User: {students.username}</h3>

          <div className='flex justify-between'>
            <h1 className='text-center text-xl'>
              Profile:
            </h1>

          </div>

          <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
            <p className='mr-2'>First Name: </p>
            <p className='text-gray-700'>{students.firstName}</p>
          </div>

          <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
            <p className='mr-2'>Last Name: </p>
            <p className='text-gray-700'>{students.lastName}</p>
          </div>

          <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
            <p className='mr-2'>Email (username): </p>
            <p className='text-gray-700'>{students.username}</p>
          </div>

        </div>

      </div>

      <div className='mt-8'>

        <div className='max-w-[500px] mx-auto px-2'>

          <div className='flex justify-between'>
            <h1 className='text-center text-xl'>
              Education:
            </h1>

          </div>

          <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
            <p className='mr-2'>Highest Degree: </p>
            <p className='text-gray-700'>{students.highestDegree}</p>
          </div>

          <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
            <p className='mr-2'>School Name: </p>
            <p className='text-gray-700'>{students.schoolName}</p>
          </div>

          <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
            <p className='mr-2'>School Address: </p>
            <p className='text-gray-700'>{students.schoolAddress}</p>
          </div>

        </div>

      </div>


      <div className='mt-8'>

        <div className='max-w-[500px] mx-auto px-2'>

          <div className='flex justify-between'>
            <h1 className='text-center text-xl'>
              Work:
            </h1>

          </div>

          <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
            <p className='mr-2'>Experience Level: </p>
            <p className='text-gray-700'>{students.experienceLevel}</p>
          </div>

          <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
            <p className='mr-2'>Job Title: </p>
            <p className='text-gray-700'>{students.jobTitle}</p>
          </div>

          <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
            <p className='mr-2'>Company Name: </p>
            <p className='text-gray-700'>{students.companyName}</p>
          </div>

          <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
            <p className='mr-2'>Job Profile: </p>
            <p className='text-gray-700'>{students.jobProfile}</p>
          </div>

          <div className='flex flex-row justify-between border-b-[1px] border-gray-300'>
            <p className='mr-2'>Skills: </p>
            <p className='text-gray-700'>{students.skills}</p>
          </div>
        </div>
      </div>

      <div className='mt-8'>

        <div className='max-w-[500px] mx-auto px-2'>

          <h4 className='text-xl'>Question History:</h4>

          {(posts.filter(post => post.creator === profileID && post.isApproved)).map((pst) => {
            return (

              <div key={pst._id} className='bg-gray-300 hover:bg-blue-200'>
                <Link to={`/post/${pst._id}`}>
                  <div className='my-2 p-2'>

                    <p className='text-lg font-semibold'>Re: {pst.title}</p>
                    <p className='text-sm'>{pst.question}</p>
                  </div>
                </Link>
              </div>
            )
          })}


        </div>
      </div>
    </div>

  )
}

export default ViewProfile