import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import baseURL from '../baseURL'

const AdminComment = () => {

  const [open, setOpen] = useState(false)

  const [comments, setComments] = useState([])
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const id = localStorage.getItem('id')
  const admin = localStorage.getItem('accountType')
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    if (!token) {
      return navigate('/login')
    }

    if (admin !== 'admin') {
      return navigate('/dashboard')
    }

    axios.get(`${baseURL}/comment`)
      .then((response) => {
        setComments(response.data)
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }, [token, update])

  const approvePost = ({ commentId }) => {
    axios.post(`${baseURL}/comment/${commentId}/approve`, {
      admin
    })
      .then((response) => {
        alert('Approved the comment')
        setUpdate((prev) => !prev)
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }

  const rejectPost = ({ commentId }) => {
    axios.post(`${baseURL}/comment/${commentId}/reject`, {
      admin
    })
      .then((response) => {
        alert('Rejected the comment')
        setUpdate((prev) => !prev)
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }

  const deleteComment = ({ commentId, postCreator }) => {

    const result = prompt('Type delete to confirm deletion?')
    console.log(result)
    if (!result) {
      return
    }

    if (result === 'delete' || result === 'Delete' || result === 'DELETE') {
      axios.delete(`${baseURL}/comment/${commentId}`)
        .then((response) => {
          alert('Comment Deleted')
          setUpdate((prev) => !prev)
        })
        .catch((error) => {
          alert(error.response.data)
        })
    }
  }

  return (
    <div className='min-h-screen mx-4 md:mx-8 lg:mx-16 xl:mx-32'>

      <div className='max-w-full mb-4'>
        <h1 className='mb-4 text-xl'>
          Admin Dashboard
        </h1>
        <Link to={'/admin'} className='p-2 m-1 bg-purple-700 text-white rounded-md hover:bg-purple-800'>Posts</Link>
        <Link to={'/adminuser'} className='p-2 m-1 bg-purple-700 text-white rounded-md hover:bg-purple-800'>Users</Link>
        <Link to={'/admincomment'} className='p-2 m-1 bg-purple-800 text-white rounded-md'>Comments</Link>
      </div>


      <div className='relative overflow-x-auto shadow-md'>

        <table className='w-full text-sm text-left text-gray-500'>
          <thead className='text-xs text-gray-700 uppercase bg-gray-300'>
            <tr>
              <th scope='col' className='px-6 py-3'>Username</th>
              <th scope='col' className='px-6 py-3'>Comment</th>
              <th scope='col' className='px-6 py-3'>Status</th>
              {/* <th scope='col' className='px-6 py-3'>Question</th>
                <th scope='col' className='px-6 py-3'>Account</th> */}
              <th scope='col' className='px-6 py-3'>Options</th>
            </tr>
          </thead>
          <tbody>
            {comments.map((comment) => {
              return (
                <tr key={comment._id} className='border-b-[1px] border-gray-300 hover:bg-gray-200'>
                  <th scope='row' className="px-6 py-1 font-medium text-gray-900 whitespace-nowrap">
                    <Link to={`/profile/${comment.creator}`}>
                      {comment.username}
                    </Link>
                  </th>
                  <td className='px-6 py-1'>
                    <Link to={`/post/${comment.question}`}>
                      {comment.comment}
                    </Link>
                  </td>
                  <td className='px-6 py-1'>
                    {comment.isApproved ? 'approved' : 'rejected'}
                  </td>
                  <td className='px-6 py-1 flex'>



                    <div class="group relative">
                      <button className='hover:text-black'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                      <span class="absolute z-10 bottom-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Reject</span>
                    </div>

                    <div class="group relative">
                      <button className='hover:text-black'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>

                      </button>
                      <span class="absolute z-10 bottom-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Approve</span>
                    </div>

                    <div class="group relative">
                      <button onClick={() => deleteComment({ commentId: comment._id })} className='hover:text-black'>

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>


                      </button>
                      <span class="absolute z-10 bottom-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Delete</span>
                    </div>


                    {/* <button data-tooltip-target="tooltip-default" type="button" className="text-black bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Default tooltip</button>
                    <div id="tooltip-default" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                      Tooltip content
                      <div className="tooltip-arrow" data-popper-arrow></div>
                    </div> */}
                    {/* <button>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    <button>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>

                    </button>
                    <button onClick={() => deleteComment({ commentId: comment._id })}>

                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>


                    </button> */}

                  </td>
                  {/* <td className='px-6 py-1'>
                      <Link to={`/post/${comment.question}`}>
                        {comment.question}
                      </Link>
                    </td>
                    <td className='px-6 py-1'>
                      <Link to={`/profile/${comment.creator}`}>
                        {comment.creator}
                      </Link>
                    </td> */}
                  {/* <td className='px-6 py-1'>{comment.isVerified ? 'approved' : 'not approved'}</td> */}
                  {/* <button onClick={() => { rejectPost({ studentId: student._id }) }}> */}
                  {/* <button onClick={() => { approvePost({ studentId: student._id }) }}> */}
                  {/* <td className='px-6 py-1'>
                      {comment.isVerified ?

<button>

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>

                        </button>
                        :
                        <button>

                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>

                        </button>
                      }
                    </td> */}
                </tr>
              )
            })}

          </tbody>
        </table>
      </div>
    </div>

  )
}

export default AdminComment