import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { Context } from '../App'
import baseURL from '../baseURL'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema1 = yup.object({
  username: yup
    .string()
    .email('Invalid email adress')
    .required('Required'),
})

const validationSchema2 = yup.object({
  code: yup
    .string()
    .length(4)
    .required('Required'),
    password: yup
    .string()
    .min(5, 'Atleast 5 characters')
    .max(35, 'Max 35 characters')
    .required('Required'),
    retryPassword: yup
    .string()
    .min(5, 'Atleast 5 characters')
    .max(35, 'Max 35 characters')
    .required('Required'),

})

const Reset = () => {

  const [open, setOpen] = useState(false)
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [retryPassword, setRetryPassword] = useState('')
  const [username, setUsername] = useState('')
  const [userName, setUserName] = useContext(Context)

  const navigate = useNavigate()
  const userId = localStorage.getItem('id')
  const isVerified = localStorage.getItem('isVerified')
  const token = localStorage.getItem('token')

  useEffect(() => {
    // if(!token){
    //     return navigate('/login')
    //   }
  }, [token])

  const formik1 = useFormik({

    initialValues: {
      username: '',
    },
    validationSchema: validationSchema1,
    onSubmit: (values, { setSubmitting, resetForm }) => {

      if (!values.username) {
        return alert('Please enter an email')
      }
  
      axios.post(`${baseURL}/resetcode`, {
        username: values.username
      })
        .then((response) => {
          setOpen(true)
          alert('Successfully sent reset code')
          // navigate('/dashboard')
        })
        .catch((error) => {
          alert(error.response.data)
        })

    }
  })


  const formik2 = useFormik({
    username,
    initialValues: {
      code: '',
      password: '',
      retryPassword: '',
    },
    validationSchema: validationSchema2,
    onSubmit: (values, { setSubmitting, resetForm }) => {

      if (!values.code || !values.password || !values.retryPassword) {
        return alert('Please fill out all fields.')
      }
  
      if (values.password !== values.retryPassword) {
        return alert('Passwords do not match.')
      }
  
      axios.post(`${baseURL}/resetpassword`, {
        code: values.code,
        password: values.password,
        username: formik1.values.username
      })
        .then((response) => {
          alert('Successfully updated password')
          navigate('/login')
        })
        .catch((error) => {
          alert(error.response.data)
        })

    }
  })


  const formSubmitSend = (e) => {
    e.preventDefault()

    if (!username) {
      return alert('Please enter an email')
    }

    axios.post(`${baseURL}/resetcode`, {
      username
    })
      .then((response) => {
        setOpen(true)
        alert('Successfully sent reset code')
        // navigate('/dashboard')
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }

  const formSubmitVerify = (e) => {
    e.preventDefault()

    if (!code || !password || !retryPassword) {
      return alert('Please fill out all fields.')
    }

    if (password !== retryPassword) {
      return alert('Passwords do not match.')
    }

    axios.post(`${baseURL}/resetpassword`, {
      code,
      username,
      password
    })
      .then((response) => {
        alert('Successfully updated password')
        navigate('/login')
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }

  return (
    <div className='min-h-screen max-w-[400px] mx-auto p-2 mt-20'>
      <h2 className='text-center mb-2 text-xl'>Reset Password</h2>
      {(!open) && <div>

        <p className='mb-8'>Please enter the registered email on the account below to receive the verification code to your email.</p>

        <form onSubmit={formik1.handleSubmit} className='flex flex-col mb-4'>

          <label className='mb-1'>Username:</label>

          <input
            id="username"
            name="username"
            type="text"
            onChange={formik1.handleChange}
            onBlur={formik1.handleBlur}
            value={formik1.values.username}
            className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
            placeholder='John.Smith@example.com'></input>
          {formik1.touched.username && formik1.errors.username ? <div className='pl-1 text-red-600'>{formik1.errors.username}</div> : null}

          <input type='submit' value='Send Verification Code' className='bg-purple-600 text-white p-2 mt-4 rounded-md hover:bg-purple-700' />
        </form>

      </div>
      }

      {(open) && <div>

        <p className='mb-8'>Please check email provided and enter the verification code your new password 2 times.</p>
        <form onSubmit={formik2.handleSubmit} className='flex flex-col mb-4'>

          <label className='mb-1'>Code:</label>

          <input
            id="code"
            name="code"
            type="text"
            onChange={formik2.handleChange}
            onBlur={formik2.handleBlur}
            value={formik2.values.code}
            className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
            placeholder='1234'></input>
          {formik2.touched.code && formik2.errors.code ? <div className='pl-1 text-red-600'>{formik2.errors.code}</div> : null}


          <label className='mb-1'>Password:</label>


          <input
            id="password"
            name="password"
            type="password"
            onChange={formik2.handleChange}
            onBlur={formik2.handleBlur}
            value={formik2.values.password}
            className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
            placeholder='••••••••'></input>
          {formik2.touched.password && formik2.errors.password ? <div className='pl-1 text-red-600'>{formik2.errors.password}</div> : null}

          <label className='mb-1'>Retype Password:</label>

          <input
            id="retryPassword"
            name="retryPassword"
            type="password"
            onChange={formik2.handleChange}
            onBlur={formik2.handleBlur}
            value={formik2.values.retryPassword}
            className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
            placeholder='••••••••'></input>
          {formik2.touched.retryPassword && formik2.errors.retryPassword ? <div className='pl-1 text-red-600'>{formik2.errors.retryPassword}</div> : null}




          <input type='submit' value='Verify' className='bg-purple-600 text-white p-2 mt-4 rounded-md hover:bg-purple-700' />
        </form>

        {/* <p className='border-t-[1px] border-gray-400 pt-2'>Don't have an account? <Link to='/register' className='text-[#ff4f5a]'>Register</Link></p> */}
      </div>}
    </div>
  )
}

export default Reset