import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import {Context} from '../App'
import baseURL from '../baseURL'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object({
  code: yup
    .string()
    .length(4)
    .required('Required'),
})

const Verify = () => {

  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')

  const [userName, setUserName] = useContext(Context)

  const navigate = useNavigate()
  const userId = localStorage.getItem('id')
  const isVerified = localStorage.getItem('isVerified')
  const token = localStorage.getItem('token')
  
  useEffect(() => {
      if(!token){
          return navigate('/login')
        }
  }, [token])


  const formik = useFormik({

    initialValues: {
      code: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {

      if(!values.code){
        return alert ('Please enter verification code')
      }
  
      axios.post(`${baseURL}/verifyemail`, {
        code: values.code,
        userId
      })
      .then((response) => {
      localStorage.setItem('isVerified', response.data.isVerified)
        alert('Successfully verified')
        navigate('/dashboard')
      })
      .catch((error) => {
        alert(error.response.data)
      })

    }
  })

  const formSubmit = (e) => {
    e.preventDefault()

    if(!code){
      return alert ('Please enter verification code')
    }
    
        console.log('hello from azios')

    axios.post(`${baseURL}/verifyemail`, {
      code,
      userId
    })
    .then((response) => {
    localStorage.setItem('isVerified', response.data.isVerified)
      alert('Successfully verified')
      navigate('/dashboard')
    })
    .catch((error) => {
      alert(error.response.data)
    })
  }

  return (
    <div className='min-h-screen max-w-[400px] mx-auto p-2 mt-20'>
      <h2 className='text-center mb-2 text-xl'>Verify Email</h2>
      <p className='mb-8'>Please check email provided and enter the verification code.</p>
      <form onSubmit={formik.handleSubmit} className='flex flex-col mb-4'>
        <label className='mb-1'>Code:</label>
        
        <input
            id="code"
            name="code"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.code}
            className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
            placeholder='1234'></input>
          {formik.touched.code && formik.errors.code ? <div className='pl-1 text-red-600'>{formik.errors.code}</div> : null}

        <input type='submit' value='Verify' className='bg-purple-600 text-white p-2 mt-4 rounded-md hover:bg-purple-700'/>
      </form>

      <p className='border-t-[1px] border-gray-400 pt-2'>Don't have an account? <Link to='/register' className='text-purple-600'>Register</Link></p>
    </div>
  )
}

export default Verify