import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { Context } from '../App'

const Navbar = () => {
  const admin = localStorage.getItem('accountType')
  const [userName, setUserName] = useContext(Context)

  const [open, setOpen] = useState(false)

  const navigation = () => {
    if (userName) {
      return (
        <React.Fragment>
          <div className='flex flex-row'>

            <div className='my-auto hidden md:flex'>
              <Link to={'/dashboard'} onClick={() => setOpen(false)} className='p-2 font-semibold border-2 border-transparent hover:border-b-white mr-8'>Dashboard</Link>
              <Link to={'/community'} onClick={() => setOpen(false)} className='p-2 font-semibold border-2 border-transparent hover:border-b-white  mr-8'>Community</Link>

            </div>
            <button className='my-auto' onClick={() => setOpen((prev) => !prev)}>

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button>

            <div className={open ? 'flex flex-col absolute w-auto h-auto bg-gray-300 text-black top-16 right-0 px-4 md:mx-8 lg:mx-16 xl:mx-32 z-10 text-left py-2 pl-2 pr-8' : 'hidden'}>

              <p className='font-bold text-left'>{userName}</p>
              <Link to={'/dashboard'} onClick={() => setOpen(false)} className='md:hidden py-2 font-semibold border-2 border-transparent hover:border-b-purple-600'>Dashboard</Link>
              <Link to={'/community'} onClick={() => setOpen(false)} className='md:hidden py-2 font-semibold border-2 border-transparent hover:border-b-purple-600'>Community</Link>
              <Link to={'/profile'} onClick={() => setOpen(false)} className='py-2 font-semibold border-2 border-transparent hover:border-b-purple-600'>Profile</Link>
              {(admin === 'admin') && <Link to={'/admin'} onClick={() => setOpen(false)} className='py-2 font-semibold border-2 border-transparent hover:border-b-purple-600'>Admin</Link>}

              <Link onClick={() => {
                localStorage.removeItem('token')
                localStorage.removeItem('student')
                localStorage.removeItem('username')
                localStorage.removeItem('isVerified')
                localStorage.removeItem('accountType')
                localStorage.removeItem('id')
                setOpen(false)
                setUserName(null)
              }} className='py-2 font-semibold border-2 border-transparent hover:border-b-purple-600'>Logout</Link>
            </div>

          </div>

        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          <div className='my-auto hidden md:flex'>
            <Link to={'/'} className='p-2 font-semibold border-2 border-transparent hover:border-b-purple-600  mr-8'>Home</Link>
            <Link to={'/contact'} className='p-2 font-semibold border-2 border-transparent hover:border-b-purple-600 mr-8'>Contact</Link>
            <Link to={'/login'} className='p-2 font-semibold border-2 border-transparent hover:border-b-purple-600'>Login</Link>

          </div>


          <div className='flex md:hidden'>
            <button className='my-auto' onClick={() => setOpen((prev) => !prev)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className={open ? 'hidden' : 'flex w-6 h-6'}>
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className={open ? 'flex w-6 h-6' : 'hidden'}>
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>

            </button>
          </div>
          <div className={open ? 'flex flex-col absolute w-full h-full bg-white text-black top-16 right-0 z-10 text-center overflow-hidden' : 'hidden'}>

            <Link to={'/'} onClick={() => setOpen(false)} className='flex justify-between p-4 font-semibold border-b-2 border-gray-300 hover:border-b-purple-600'>
              Home
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </Link>
            
            <Link to={'/contact'} onClick={() => setOpen(false)} className='flex justify-between p-4 font-semibold border-b-2 border-gray-300 hover:border-b-purple-600'>
              Contact
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </Link>

            <Link to={'/login'} onClick={() => setOpen(false)} className='flex justify-between p-4 font-semibold border-b-2 border-gray-300 hover:border-b-purple-600'>
              Login
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </Link>

          </div>
        </React.Fragment>
      )
    }
  }

  return (
    <header className=''>
    {/* <header className='bg-purple-600 text-white'> */}

    <div className='py-2 md:py-0 mx-4 md:mx-8 lg:mx-16 xl:mx-32 flex justify-between'>
        <Link to={'/'} onClick={() => setOpen(false)} className='mr-8 text-2xl md:text-4xl font-semibold'>

      <img src='/images/sitefix-logo2.png' className='w-[200px]'/>
        </Link>
      {/* <h1>
        <Link to={'/'} onClick={() => setOpen(false)} className='mr-8 text-2xl md:text-4xl font-semibold'>Site Fix</Link>
      </h1> */}

      {navigation()}


    </div>
      </header>
  )
}
export default Navbar