
import React, { useState, createContext, useContext } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Contact from './pages/Contact'
import About from './pages/About'
import Portfolio from './pages/Portfolio';
import Login from './pages/Login'
import Register from './pages/Register'
import Dashboard from './pages/Dashboard'
import Community from './pages/Community'
import Navbar from './components/Navbar';
// import Footer from './components/Footer'
import CreatePost from './pages/CreatePost'
import Post from './pages/Post'
import ViewProfile from './pages/ViewProfile'
import EditProfile from './pages/EditProfile'
import Profile from './pages/Profile'
import EditEducation from './pages/EditEducation'
import EditWork from './pages/EditWork'
import Error from './pages/Error'
import Verify from './pages/Verify'
import Reset from './pages/Reset'
import Admin from './pages/Admin'
import AdminUser from './pages/AdminUser'
import AdminComment from './pages/AdminComment'
import Footer from './components/Footer'

export const Context = React.createContext();

function App() {

  const [userName, setUserName] = useState(localStorage.getItem('username'));

  return (
    <div>
    <Context.Provider value={[userName, setUserName]}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/verify' element={<Verify />} />
          <Route path='/reset' element={<Reset />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/adminuser' element={<AdminUser />} />
          <Route path='/admincomment' element={<AdminComment />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='/community' element={<Community />} />
          <Route path='/createpost' element={<CreatePost />} />
          <Route path='/post/:postID' element={<Post />} />
          <Route path='/profile/:profileID' element={<ViewProfile />} />
          <Route path='/editprofile' element={<EditProfile />} />
          <Route path='/editeducation' element={<EditEducation />} />
          <Route path='/editwork' element={<EditWork />} />
        <Route path='/*' element={<Error />} /> 
        </Routes>
        <Footer/>
      </BrowserRouter>
    </Context.Provider>

    </div>
  );
}

export default App;
