import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Context } from '../App'
import baseURL from '../baseURL'
import Sidebar from '../components/Sidebar'
import Landing from './Landing'
import Education from './Education'
import Work from './Work'


const Profile = () => {

  const [students, setStudents] = useState([])
  const navigate = useNavigate()

  const [userName, setUserName] = useContext(Context)

  const token = localStorage.getItem('token')
  const student = localStorage.getItem('student')

  useEffect(() => {
    if (!token) {
      return navigate('/login')
    }

  }, [token, navigate])


  return (
    <div className='min-h-screen mx-4 md:mx-8 lg:mx-16 xl:mx-32'>
      <div className='max-w-[500px] mx-auto px-2'>
        <h1 className='text-xl mb-8'>Account Settings</h1>
      </div>
      <Landing />
      <Education />
      <Work />
      <div className='mt-8'>

        <div className='max-w-[500px] mx-auto px-2'>

          <Link to={'/reset'} className='bg-purple-600 p-2 text-white'>Change Password</Link>
        </div>
      </div>
    </div>
  )
}

export default Profile