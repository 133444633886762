import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import baseURL from '../baseURL'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object({
  title: yup
    .string()
    .required('Required'),
  question: yup
    .string()
    .max(280, 'Max 280 characters')
    .required('Required'),
})

const CreatePost = () => {

  const [creator, setCreator] = useState(localStorage.getItem('id'))
  const [username, setUsername] = useState(localStorage.getItem('username'))

  const navigate = useNavigate()


  const formik = useFormik({

    initialValues: {
      title: '',
      question: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (!values.title || !values.question) {
        return alert('Please fill out all fields.')
      }

      axios.post(`${baseURL}/post`, {
        title: values.title,
        question: values.question,
        creator,
        username
      })
        .then((response) => {
          alert('New post created')
          navigate('/community')
        })
        .catch((error) => {
          alert(error.response.data)
        })
    }
  })


  return (
    <div className='min-h-screen max-w-[400px] mx-auto p-2 mt-20'>
      <h2 className='text-center mb-2 text-xl'>Create Post</h2>
      <form onSubmit={formik.handleSubmit} className='flex flex-col mb-4'>

        <label className='mb-1'>Title:</label>
        <input
          id="title"
          name="title"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title}
          className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
          placeholder='How To Register?'></input>
        {formik.touched.title && formik.errors.title ? <div className='pl-1 text-red-600'>{formik.errors.title}</div> : null}

        <label className='mb-1'>Question:</label>
        <input
          id="question"
          name="question"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.question}
          className='p-2 mb-2 border-[1px] border-gray-400 rounded-md'
          placeholder='How do you register for an acc...'></input>
        {formik.touched.question && formik.errors.question ? <div className='pl-1 text-red-600'>{formik.errors.question}</div> : null}

        <input type='submit' value='Create' className='bg-purple-600 text-white p-2 mt-4 rounded-md hover:bg-purple-700' />
      </form>

    </div>
  )
}

export default CreatePost