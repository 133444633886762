import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <div className='mx-4 md:mx-8 lg:mx-16 xl:mx-32'>
      <p>
        Error page not found, please return <Link to={'/'} className='text-[#ff4f5a]'>Home</Link>.
      </p>
    </div>
  )
}

export default Error