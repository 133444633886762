import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import baseURL from '../baseURL'

const Community = () => {

  const [posts, setPosts] = useState([])
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const id = localStorage.getItem('id')

  useEffect(() => {
    if (!token) {
      return navigate('/login')
    }

    axios.get(`${baseURL}/post`)
      .then((response) => {
        setPosts(response.data)
      })
      .catch((error) => {
        alert(error.response.data)
      })
  }, [token, navigate])

  const deletePost = ({ postId, postCreator }) => {

    if (postCreator !== id) {
      return alert('Error, can only delete your own post.')
    }

    axios.delete(`${baseURL}/post/${postId}`)
      .then((response) => {
        alert('Post Deleted')
        window.location.reload()
      })
      .catch((error) => {
        alert(error.response.data)
      })

  }

  return (
    <div className='min-h-screen mx-4 md:mx-8 lg:mx-16 xl:mx-32'>

      <div className='max-w-full'>
        <h1 className='mb-4 text-xl'>
          Community
        </h1>
        <Link to={'/createpost'} className='bg-purple-600 text-white px-2 py-1 hover:bg-purple-700'>Add Post</Link>

      </div>
      {(posts.filter(pst => pst.isApproved)).map((post) => {
        return (
          <div key={post._id} className='border-l-[5px] border-transparent hover:border-purple-600'>
            <Link to={`/post/${post._id}`}>
              <div className='my-2 p-2 border-b-gray-300 border-[1px]'>

            <p className='text-lg font-semibold'>Re: {post.title}</p>
            <p className='text-sm'>By: {post.username}</p>
            <p className='text-sm'>{post.question}</p>
              </div>
            </Link>
          </div>

        )
      })
      }

    </div>

  )
}

export default Community