import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='flex flex-col md:flex-row md:gap-2 justify-center bg-black text-white text-center py-2'>
        <p>Copyright 2024 Site Fix</p>
        <Link to={'/'}>Home</Link>
        <Link to={'/login'}>Login</Link>
        <Link to={'/contact'}>Contact</Link>
    </div>
  )
}

export default Footer