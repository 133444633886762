import React, {useState} from 'react'
import { Link } from 'react-router-dom'

const When = () => {
  const [show, setShow] = useState(false)

  return (
    <div className='px-4 sm:mx-0 py-8 md:py-16'>
      <div className='max-w-[600px] text-center mx-auto'>
        <h3 className='text-2xl xl:text-4xl font-semibold tracking-tight mb-8'>When Can I Get Help?</h3>
        <button onClick={() => setShow((prev) => !prev)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
</svg>
</button>
{show && <div>
        <p className='text-lg mb-8 text-left sm:text-center'>Our community board is open 24/7 for you if any inquiries should rise. Just create a post and other users can comment to help with your question or concern.</p>
        <Link to={'/login'} className='p-2 bg-black text-white  mr-2 font-semibold'>Login</Link>
        </div>}
      </div>
    </div>
  )
}

export default When