import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Hero from '../components/Hero copy 9'
import Who from '../components/Who'
import What from '../components/What'
import Where from '../components/Where'
import When from '../components/When'
import Why from '../components/Why'
import How from '../components/How'
import Banner from '../components/Banner'

const Home = () => {

  const navigate = useNavigate()
  const token = localStorage.getItem('token')

  useEffect(() => {
    if (token) {
      navigate('/dashboard')
    }
  }, [])

  return (
    <div>
      <Hero/>
      {/* <Banner/> */}
      <Who/>
      <What/>
      <Where/>
      <When/>
      <Why/>
      <How/>
      

    </div>
  )
}

export default Home