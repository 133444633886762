import React from 'react'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className='max-h-[800px] overflow-hidden'>

      {/* <div className='w-[600px] ml-32 mt-32'> */}
      <div className='mx-4 mt-4 p-8 md:w-[800px] md:p-16 lg:ml-32 lg:mt-16 bg-purple-400 bg-opacity-50 rounded'>
        <h2 className='text-2xl md:text-4xl font-semibold mb-8'>Make Your Website a <span className=''>Success</span></h2>
        <p className='mb-8 text-lg md:text-xl mx-auto md:mx-0'>Register for a free account to learn website design layouts and fundamental ui/ux practices that you can implement today. Explore our premium web services for a more hands off and guided approach.</p>
        <div className='flex flex-col md:flex-row'>
        <Link to={'/register'} className='inline-flex max-w-[160px] p-4 md:mr-2 text-white bg-black font-semibold hover:bg-gray-800'>
          Start Learning
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 ml-2 my-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
          </svg>
        </Link>
        <Link to={'/login'} className='max-w-[160px] p-4 rounded-md text-black font-semibold hover:underline'>Explore Services</Link>
        </div>
      </div>

      {/* <div className='w-[800px] py-16 px-16 ml-32 mt-16 bg-purple-400 bg-opacity-50 rounded'>
        <h2 className='text-4xl font-semibold mb-8'>Make Your Website a <span className=''>Success</span></h2>
        <p className='mb-8 text-xl mx-auto md:mx-0'>Register for a free account to learn website design layouts and fundamental ui/ux practices that you can implement today. Explore our premium web services for a more hands off and guided approach.</p>
        <Link to={'/register'} className='inline-flex p-4 mr-2 text-white bg-black font-semibold hover:bg-gray-800'>
          Start Learning
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 ml-2 my-auto">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
          </svg>
        </Link>
        <Link to={'/login'} className='p-4 rounded-md text-black font-semibold hover:underline'>Explore Services</Link>
      </div> */}

      <div className='bg-blue-400 bg-opacity-60 rounded text-center p-4 mt-8 mx-4 md:py-12 md:px-16 lg:ml-32 md:w-[500px]'>
        <h3 className=' font-semibold text-black'>Tutorials - Website Help - Website Services</h3>
      </div>

      <img src='/images/6.png' className='hidden md:flex w-[1800px] -z-10 absolute top-4 -right-12' />

    </div>
  )
}

export default Hero