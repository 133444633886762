import React, { useState } from 'react'

const Who = () => {
    const [showNew, setShowNew] = useState(false)
    const [showExisting, setShowExisting] = useState(false)
    return (
        <div className='py-16 mt-44 px-4'>
            <div className='max-w-[1200px] mx-auto'>


                <h3 className='max-w-[600px] mx-auto text-2xl xl:text-4xl text-center font-semibold tracking-tight mb-8'>Who Should Use This Service?</h3>
                <p className='max-w-[600px] mx-auto text-lg text-gray-700 mb-8'>Whether your looking to update your existing website or looking for a totally new web presence let us show you the difference an optimized approach can have on your business.</p>


                <div className='flex flex-col md:flex-row gap-4 py-8'>
                    <div className='flex-1 text-center'>
                        {/* <button onClick={() => setShowNew((prev) => !prev)} className='inline-flex border-b-2 border-purple-600 text-2xl py-2 px-4 hover:font-semibold'> */}
                        {!showNew && <button onClick={() => setShowNew((prev) => !prev)} className='inline-flex bg-black border-b-8 border-purple-800 text-2xl text-white py-2 px-4 hover:border-purple-700 transition duration-300'>
                            Looking For A Brand New Website

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 ml-2 my-auto">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                            
                        </button>}

                        {showNew && <div><button onClick={() => setShowNew((prev) => !prev)} className='inline-flex bg-black border-b-8 border-purple-600 text-2xl text-white py-2 px-4'>
                            Looking For A Brand New Website
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 ml-2 my-auto">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>


                        </button>

                    
                            <div className='max-w-[400px] mx-auto text-left'>
                                <p>1. Check out the basics of the purpose of a website.</p>
                                <p>2. Learn what type of functionailty you need.</p>
                                <p>3. Explore your options in having a website.</p>
                            </div>
                            </div>
                            }

                    </div>
                    <div className='flex-1 text-center'>
                        {!showExisting && <button onClick={() => setShowExisting((prev) => !prev)} className='inline-flex bg-black border-b-8 border-purple-800 hover:border-purple-700 transition duration-300 text-white text-2xl py-2 px-4'>
                            Already Have An Existing Website
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 ml-2 my-auto">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                            </svg>

                        </button>}
                        {showExisting && <button onClick={() => setShowExisting((prev) => !prev)} className='inline-flex bg-black border-b-8 border-purple-600 text-white text-2xl py-2 px-4'>
                            Already Have An Existing Website
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 ml-2 my-auto">
                                <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                            </svg>
                        </button>}
                        {showExisting &&
                            <div className='text-left max-w-[400px] mx-auto'>
                                
                                <p>1. Check out an overview of basic design layouts</p>
                                <p>2. Learn how to test your existing website to check its performance</p>
                                <p>3. Explore methods on how to improve your website</p>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Who