import React, { useState } from 'react'


const Why = () => {
  const [show, setShow] = useState(false)

  return (
    <div className='px-4 sm:mx-0 py-8 md:py-16 bg-purple-300'>
      <div className='max-w-[600px] text-center mx-auto'>

        <h3 className='text-2xl xl:text-4xl text-center font-semibold tracking-tight mb-8'>Why Should I Use Your Services?</h3>
        <button onClick={() => setShow((prev) => !prev)}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
        </svg>
        </button>
        {show && <div>

          <p className='text-lg mb-8'>We offer a wide variety of services for website owners. We offer an initial free consultation to get an idea of where you want to take your website. Take advantage of our complimentary website review so you can see where you stand and included plan to see where you can go.</p>

          <div className='max-w-[350px] mx-auto text-left'>

            <div className='flex flex-row mt-8 mb-4'>

              <p className='font-semibold text-gray-700 mb-2 text-xl mx-auto'>The SiteFix Advantage</p>
            </div>

            <div className='flex flex-row mb-2'>

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9333ea" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

              <p className='font-semibold text-gray-700 ml-1 my-auto'>Comprehensive review for existing websites</p>
            </div>

            <div className='flex flex-row mb-2'>

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9333ea" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

              <p className='font-semibold text-gray-700 ml-1 my-auto'>Complete website plan addressing major concerns</p>
            </div>

            <div className='flex flex-row mb-2'>

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9333ea" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

              <p className='font-semibold text-gray-700 ml-1 my-auto'>Dedicated account manager available for assisstance</p>
            </div>

            <div className='flex flex-row'>

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9333ea" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>

              <p className='font-semibold text-gray-700 ml-1 my-auto'>Access to the community board</p>
            </div>

          </div>
        </div>}

      </div>
    </div>
  )
}

export default Why