import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Context } from '../App'
import baseURL from '../baseURL'
import { useFormik } from 'formik'
import * as yup from 'yup'

const validationSchema = yup.object({
    experienceLevel: yup
        .string()
        .required('Required'),
    jobTitle: yup
        .string()
        .required('Required'),
    companyName: yup
        .string()
        .required('Required'),
    jobProfile: yup
        .string()
        .required('Required'),
    skills: yup
        .string()
        .required('Required'),
})

const EditWork = () => {

    const [students, setStudents] = useState([])
    const navigate = useNavigate()
    const [userName, setUserName] = useContext(Context)
    const token = localStorage.getItem('token')
    const id = localStorage.getItem('id')

    useEffect(() => {
        if (!token) {
            return navigate('/login')
        }

        axios.post(`${baseURL}/work`, { id })
            .then((response) => {
                setStudents(response.data)
            })
            .catch((error) => {
                alert(error)
            })

    }, [token, navigate])

    const formik = useFormik({

        initialValues: {
            experienceLevel: '',
            jobTitle: '',
            companyName: '',
            jobProfile: '',
            skills: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {

            axios.post(`${baseURL}/editwork`, {
                experienceLevel: values.experienceLevel,
                jobTitle: values.jobTitle,
                companyName: values.companyName,
                jobProfile: values.jobProfile,
                skills: values.skills,
                id
            })
                .then((response) => {
                    alert('Work updated')
                    navigate('/profile')
                })
                .catch((error) => {
                    alert(error.response.data)
                })

        }
    })

    return (
        <div className='min-h-screen mx-4 py-16'>

            <div className='max-w-[400px] mx-auto'>
                <div>

                    <h1 className='text-center text-xl mb-8'>
                        Edit work for user: {userName}
                    </h1>

                    <form onSubmit={formik.handleSubmit} className='flex flex-col mb-4'>
                        <label className='mb-1'>Experience Level:</label>

                        <input
                            id="experienceLevel"
                            name="experienceLevel"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.experienceLevel}
                            placeholder={students.experienceLevel}
                            className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
                        {formik.touched.experienceLevel && formik.errors.experienceLevel ? <div className='pl-1 text-red-500'>{formik.errors.experienceLevel}</div> : null}

                        <label className='mb-1'>Job Title:</label>

                        <input
                            id="jobTitle"
                            name="jobTitle"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.jobTitle}
                            placeholder={students.jobTitle}
                            className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
                        {formik.touched.jobTitle && formik.errors.jobTitle ? <div className='pl-1 text-red-500'>{formik.errors.jobTitle}</div> : null}


                        <label className='mb-1'>Company Name:</label>
                        <input
                            id="companyName"
                            name="companyName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.companyName}
                            placeholder={students.companyName}
                            className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
                        {formik.touched.companyName && formik.errors.companyName ? <div className='pl-1 text-red-500'>{formik.errors.companyName}</div> : null}

                        <label className='mb-1'>Job Profile:</label>
                        <input
                            id="jobProfile"
                            name="jobProfile"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.jobProfile}
                            placeholder={students.jobProfile}
                            className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
                        {formik.touched.jobProfile && formik.errors.jobProfile ? <div className='pl-1 text-red-500'>{formik.errors.jobProfile}</div> : null}

                        <label className='mb-1'>Skills:</label>
                        <input
                            id="skills"
                            name="skills"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.skills}
                            placeholder={students.skills}
                            className='border-[1px] border-gray-400 mb-2 p-2 rounded-md' />
                        {formik.touched.skills && formik.errors.skills ? <div className='pl-1 text-red-500'>{formik.errors.skills}</div> : null}

                        <input type='submit' value='Update' className='bg-purple-700 text-white p-2 rounded-md hover:bg-purple-800' />
                    </form>

                </div>
                <Link to={'/profile'} className='text-purple-600'>Back To Profile</Link>
            </div>
        </div>

    )
}

export default EditWork