import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Context } from '../App'
import baseURL from '../baseURL'
import Sidebar from '../components/Sidebar'


const Dashboard = () => {

  const [students, setStudents] = useState([])
  const navigate = useNavigate()

  const [userName, setUserName] = useContext(Context)
  const [posts, setPosts] = useState([])
  const token = localStorage.getItem('token')
  const student = localStorage.getItem('student')

  useEffect(() => {
    if (!token) {
      return navigate('/login')
    }
    axios.get(`${baseURL}/post`)
      .then((response) => {
        setPosts(response.data)
      })
      .catch((error) => {
        alert(error.response.data)
      })

  }, [token, navigate])


  return (
    <div className='min-h-screen mx-4 md:mx-8 lg:mx-16 xl:mx-32'>
      <h3 className='text-xl'>Dashboard</h3>
      <p>Welcome Back, {userName}!</p>

      <div className='mt-8 bg-gray-700 text-white p-2'>
        <h4 className='text-xl mb-4'>Newly Registered?</h4>
        <p className='text-gray-200'>
          <Link to={'/profile'} className='flex justify-between py-2 hover:bg-purple-600'>
            Go to profile and update your information
            <div className='my-auto'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </div>
          </Link>
        </p>
        <p className='text-gray-200'>
          <Link to={'/community'} className='flex justify-between py-2 hover:bg-purple-600'>
            Go to community page and view questions and join in on the conversation
            <div className='my-auto'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </div>
          </Link>
        </p>
      </div>

      <div className='mt-8'>
        <h4 className='text-xl'>Your Previous Questions:</h4>

        {posts.filter(post => post.username == userName).map((pst) => {
          return (
            <div key={pst._id} className='border-l-transparent border-l-[5px] hover:border-purple-600'>
              <Link to={`/post/${pst._id}`}>
                <div className='my-2 p-2 border-b-gray-300 border-b-[1px]'>
                  <p className='text-lg font-semibold'>Re: {pst.title}</p>
                  <p className='text-sm'>By: {pst.username}</p>
                  <p className='text-sm'>{pst.question}</p>
                  {(pst.isApproved) ? <p className='text-sm'>Status: Approved</p> :
                    <p className='text-sm'>Status: Rejected</p>
                  }
                </div>
              </Link>
            </div>
          )
        })
        }

        {(posts.filter(post=> post.username === userName)).length < 1 &&
        <div className='mt-8 text-[#ff4f5a]'>
        <Link to={'/createpost'}>
          No active questions, create a new question.
        </Link>
      </div>
      }

        {(posts.filter(post=> post.username === userName)).length > 0 &&
        <h2>
          You have asked {(posts.filter(post=> post.username == userName)).length} question(s).
        </h2>
      }

      </div>

    </div>
  )
}

export default Dashboard